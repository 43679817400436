import * as React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import classname from 'classnames';
import { Text } from 'wix-ui-tpa/Text';
import { Input } from 'wix-ui-tpa/Input';
import { Button, PRIORITY } from 'wix-ui-tpa/Button';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { GroupLogoUpload } from '../../GroupLogoUpload/intex';
import { CreateGroupAction } from '../../App/types';
import { GroupTypesDropdown } from './GroupTypesDrowdown';
import {
  Loader,
  TPAModal,
  TPAModalProps,
  withTPAConfig,
} from '@wix/social-groups-common/dist/src/components';
import { TPAComponentsConfig } from 'wix-ui-tpa/TPAComponentsConfig';
import withGroups from '../../../contexts/withGroups';
import { DATA_HOOKS } from './dataHooks';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { WithGroupsProps } from '../../../contexts/withGroupsProps';

import styles from './CreateGroupModal.st.css';

const SOCIAL_GROUP_NAME_MAX_LENGTH = 50;
const DEFAULT_GROUP_PRIVACY_LEVEL = ApiTypes.v1.GroupPrivacyLevel.PUBLIC;

export interface CreateGroupModalProps extends CreateGroupAction {
  withPendingGroupExplanation?: boolean;
}

interface CreateGroupState {
  title: string;
  privacyLevel: ApiTypes.v1.GroupPrivacyLevel;
}

export class CreateGroupModal extends React.Component<
  CreateGroupModalProps &
    InjectedTranslateProps &
    WithGroupsProps &
    TPAComponentsConfig &
    TPAModalProps,
  CreateGroupState
> {
  private readonly defaultState = {
    title: '',
    privacyLevel: DEFAULT_GROUP_PRIVACY_LEVEL,
  };
  private groupImage: File;
  readonly state = this.defaultState;

  componentWillReceiveProps = nextProps => {
    if (!nextProps.isOpen && this.props.isOpen) {
      this.setState(this.defaultState);
    }
  };

  handleOnSubmit = e => {
    e.preventDefault();
    const { title, privacyLevel } = this.state;
    if (this.isValidTitle() && !this.props.isGroupCreating) {
      this.props.createGroup({ title }, { privacyLevel }, this.groupImage);
    }
  };

  isValidTitle() {
    const { title } = this.state;
    return !!title;
  }

  setSocialGroupName = e => {
    this.setState({
      title: e.target.value,
    });
  };

  setSocialGroupType = option => {
    this.setState({ privacyLevel: option.id });
  };

  render() {
    const {
      t,
      onRequestClose,
      isGroupCreating,
      isOpen,
      mobile,
      withPendingGroupExplanation,
    } = this.props;
    const { title, privacyLevel } = this.state;

    const isDisabledCreateButton = !this.isValidTitle() || isGroupCreating;
    return (
      <TPAModal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        inFullScreen={mobile}
        {...styles(styles.root, { mobile, withPendingGroupExplanation })}
      >
        <form onSubmit={this.handleOnSubmit} data-hook={DATA_HOOKS.root}>
          <Text className={styles.title}>
            {t('groups-web.modals.create-group.title')}
          </Text>
          <GroupLogoUpload
            className={styles.logoUpload}
            uploadImage={this.setImage}
          />

          <div className={styles.nameControl}>
            <Text {...styles(styles.label, {})}>
              <span>{t('groups-web.modals.create-groups.groupName')}</span>
              <span {...styles(styles.counter, {})}>
                {title.length}/{SOCIAL_GROUP_NAME_MAX_LENGTH}
              </span>
            </Text>
            <Input
              data-hook={DATA_HOOKS.groupName}
              maxLength={SOCIAL_GROUP_NAME_MAX_LENGTH}
              placeholder={t(
                'groups-web.modals.create-groups.groupName.placeholder',
              )}
              onChange={this.setSocialGroupName}
            />
          </div>

          <div className={styles.privacyControl}>
            <Text {...styles(styles.label, {})}>
              <span>{t('groups-web.modals.create-groups.groupType')}</span>
            </Text>
            <div>
              <GroupTypesDropdown
                selectedType={privacyLevel}
                onSelect={this.setSocialGroupType}
              />
            </div>
            {withPendingGroupExplanation ? (
              <Text
                className={classname(
                  styles.label,
                  styles.pendingGroupExplanation,
                )}
              >
                {t('groups-web.modals.create-group.pending-group-explanation')}
              </Text>
            ) : null}
          </div>

          <div className={styles.buttons}>
            <div className={styles.buttonWrapper}>
              <Button
                data-hook={DATA_HOOKS.cancelButton}
                priority={PRIORITY.secondary}
                onClick={onRequestClose}
              >
                {t('groups-web.btn.cancel')}
              </Button>
            </div>
            <Button
              data-hook={DATA_HOOKS.createButton}
              priority={PRIORITY.primary}
              type="submit"
              disabled={isDisabledCreateButton}
              prefixIcon={isGroupCreating && <Loader />}
            >
              {isGroupCreating
                ? t('groups-web.btn.creating')
                : t('groups-web.btn.create')}
            </Button>
          </div>
        </form>
      </TPAModal>
    );
  }

  private readonly setImage = (image: File) => {
    this.groupImage = image;
  };
}

const enhance = compose(withGroups, withTPAConfig, translate());

export default enhance(CreateGroupModal);
