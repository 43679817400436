import * as React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { PRIORITY } from 'wix-ui-tpa/Button';

import withGroups from '../../contexts/withGroups';
import {
  withAppSettings,
  WithAppSettingsProps,
} from '../../contexts/withAppSettings';
import withGroupsActions, {
  WithGroupsActionsProps,
} from '../../contexts/withGroupsActions';

import styles from './Groups.st.css';
import {
  AppToasts,
  InjectedWebOutProps,
  withAppToasts,
  withTPAConfig,
  withWebOut,
} from '@wix/social-groups-common';
import { WithAppToastsProps } from '@wix/social-groups-common/dist/src/types';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import {
  canCreateGroup,
  canResolvePendingGroups,
} from '@wix/social-groups-api';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';

import { Button } from '../Button/Button';
import { SearchSortingBox } from '../SearchSortingBox/SearchSortingBox';
import { DATA_HOOKS } from './dataHooks';
import {
  withCurrentUser,
  WithCurrentUserProps,
} from '../../contexts/withCurrentUser';
import { PendingGroupList } from '../PendingGroupList';
import {
  BIUserEntry,
  MemberInvites,
  tryToCallBi,
  withBiLogger,
  WithBiLoggerProps,
  withMemberInvites,
  withWixSiteMembers,
  WixSiteMember,
  WixSiteMemberActions,
} from '@wix/social-groups-common/dist/src/context';
import { WithGroupsProps } from '../../contexts/withGroupsProps';
import { GroupsSectionComponent } from './GroupsSection';
import {
  InjectedExperimentsProps,
  withExperiments,
} from '@wix/wix-experiments-react';
import { showSearchSortingBox, showTitle } from '../../settings/utils';

const isApprovalStatusPending = group =>
  group.approvalStatus === ApiTypes.v1.GroupApprovalStatus.PENDING;

export interface GroupsProps {}

export interface GroupsState {
  isCreateGroupModalOpened: boolean;
  isGroupCreating: boolean;
  isProfileDialogOpen: boolean;
}

export type ComponentProps = InjectedTranslateProps &
  GroupsProps &
  WithCurrentUserProps &
  WithGroupsProps &
  WithGroupsActionsProps &
  WithAppToastsProps &
  WixSiteMember &
  WixSiteMemberActions &
  WithAppSettingsProps & { mobile: boolean } & InjectedWebOutProps &
  MemberInvites &
  InjectedExperimentsProps &
  WithBiLoggerProps;

export class GroupsComponent extends GroupsSectionComponent {
  static getDerivedStateFromProps(nextProps, prevState): Partial<GroupsState> {
    const { isGroupCreating } = nextProps;
    const nextState: Partial<GroupsState> = {
      isGroupCreating,
    };
    if (isGroupCreating === false && prevState.isGroupCreating === true) {
      nextState.isCreateGroupModalOpened = false;
    }

    return nextState;
  }

  renderTitle() {
    const { appSettings, t } = this.props;

    return (
      <h2>
        {appSettings.text.groupListTitle ||
          t('groups-web.settings.TextTab.groups.default')}
      </h2>
    );
  }

  render() {
    const {
      actions,
      createGroupPolicy,
      currentUser,
      groupsSortBy,
      appSettings,
      groups,
      t,
      toasts,
      mobile,
      groupSectionUrl,
    } = this.props;

    const withTitle = showTitle(appSettings, mobile);
    const withSearchSortingBox = showSearchSortingBox(appSettings, mobile);

    const shouldShowCreateGroupButton = canCreateGroup(
      currentUser,
      createGroupPolicy,
    );

    const couldUserResolvePendingGroups = canResolvePendingGroups(currentUser);
    const shouldShowPendingGroupList =
      groups && groups.some(isApprovalStatusPending);

    return (
      <div data-hook="groups" {...styles('root', { mobile })}>
        <AppToasts t={t} {...toasts} />
        <div className={styles.header}>
          <Text
            tagName="h2"
            typography={TYPOGRAPHY.largeTitle}
            {...styles(styles.title, {
              headerAlignment: appSettings.layout.headerAlignment,
            })}
          >
            {withTitle &&
              (appSettings.text.groupListTitle ||
                t('groups-web.settings.TextTab.groups.default'))}
          </Text>
          {/* TODO: refactor for widget (+ performance) */}
          {shouldShowCreateGroupButton && (
            <Button
              priority={PRIORITY.secondary}
              data-hook={DATA_HOOKS.createNewButton}
              onClick={this.handleCreateGroupClick}
              {...styles(styles.createNewButton, {})}
            >
              {t('groups-web.btn.create-new')}
            </Button>
          )}
        </div>
        {shouldShowPendingGroupList && (
          <PendingGroupList
            data-hook={DATA_HOOKS.pendingGroupList}
            couldUserResolvePendingGroups={couldUserResolvePendingGroups}
            actions={actions}
            groups={groups}
            mobile={mobile}
            groupSectionUrl={groupSectionUrl}
          />
        )}
        {withSearchSortingBox ? (
          <SearchSortingBox
            onSearch={this.handleSearch}
            onSort={actions.sortGroups}
            initialSortSelectedId={groupsSortBy}
          />
        ) : (
          <div className={styles.noop} />
        )}
        {super.render()}
      </div>
    );
  }

  private readonly handleSearch = (value: string) => {
    const { actions, biLogger } = this.props;
    tryToCallBi(async () => {
      await biLogger.groupsSearchForGroup({
        group_name: value,
        origin: 'top_panel_editbox',
        userEntry: BIUserEntry.SITE,
      });
    });
    actions.searchGroups(value);
  };
}

const enhance = compose(
  withGroups,
  withCurrentUser,
  withGroupsActions,
  withAppToasts,
  withWixSiteMembers,
  withAppSettings,
  translate(),
  withTPAConfig,
  withWebOut,
  withMemberInvites,
  withBiLogger,
  withExperiments,
);
export const Groups = enhance(GroupsComponent) as React.ComponentType<
  GroupsProps
>;
