import * as React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { ImageLoader } from '@wix/social-groups-common/dist/src/components';

export interface GroupLogoUploadProps {
  uploadImage(image: File);
  className?: string;
}

export interface GroupLogoUploadState {
  loading: boolean;
  logoUrl: string;
}

export class GroupLogoUploadComponent extends React.Component<
  GroupLogoUploadProps & InjectedTranslateProps,
  GroupLogoUploadState
> {
  render() {
    const { uploadImage, t, className } = this.props;
    return (
      <ImageLoader
        uploadImage={uploadImage}
        uploadImageLabel={t('groups-web.modal.create-group.image.upload')}
        changeImageLabel={t('groups-web.modal.create-group.image.change')}
        deleteImageLabel={t('groups-web.modal.create-group.image.delete')}
        loadingLabel={t('groups-web.loading')}
        className={className}
      />
    );
  }
}

export const GroupLogoUpload = translate()(
  GroupLogoUploadComponent,
) as React.ComponentType<GroupLogoUploadProps>;
