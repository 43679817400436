import * as React from 'react';
import styles from './GroupList.st.css';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';

interface NoGroupsProps {
  emptyStateHeader: string;
  emptyStateText: string;
  actionButton?: JSX.Element;
}

export const NoGroups: React.FC<NoGroupsProps> = ({
  emptyStateHeader,
  emptyStateText,
  actionButton,
}) => {
  return (
    <div className={styles.emptyRoot}>
      <Text
        typography={TYPOGRAPHY.smallTitle}
        {...styles(styles.emptyStateHeader, {})}
      >
        {emptyStateHeader}
      </Text>
      <Text
        typography={TYPOGRAPHY.runningText}
        {...styles(styles.emptyStateText, {})}
      >
        {emptyStateText}
      </Text>
      {actionButton}
    </div>
  );
};
