import * as React from 'react';
import { translate, InjectedTranslateProps } from 'react-i18next';

import { Button } from 'wix-ui-tpa/Button';
import { Text } from 'wix-ui-tpa/Text';
import { WebOut } from '@wix/social-groups-common';
import { compose } from '@wix/social-groups-common/dist/src/compose';

import styles from './PendingGroupList.st.css';
import {
  MemberInvites,
  withMemberInvites,
} from '@wix/social-groups-common/dist/src/context';

export interface PendingGroupsMobileProps {
  total: number;
}

export interface PendingGroupsMobileState {
  isOpened: boolean;
}

export class PendingGroupsMobileComponent extends React.Component<
  PendingGroupsMobileProps & InjectedTranslateProps & MemberInvites,
  PendingGroupsMobileState
> {
  readonly state: PendingGroupsMobileState = {
    isOpened: false,
  };

  openWebOut = () => this.setState({ isOpened: true });

  closeWebOut = () => this.setState({ isOpened: false });

  render() {
    const { t, memberInvitesLink, total, loadMemberInvitesLink } = this.props;
    const { isOpened } = this.state;

    return (
      <>
        <Button onClick={this.openWebOut} {...styles('root', { mobile: true })}>
          <Text className={styles.mobileLabel}>
            {t('groups-web.group-list.pending-groups-mobile.label', {
              count: total,
            })}
          </Text>
          &nbsp;
          <Text className={styles.mobileCta}>
            {t('groups-web.group-list.pending-groups-mobile.cta')}
          </Text>
        </Button>
        <WebOut
          inviteLink={memberInvitesLink}
          loadMemberInvitesLink={loadMemberInvitesLink}
          buttonLabelKey={t('groups-web.modal.out.button')}
          title={t('groups-web.modal.out.pending-groups.title')}
          caption={t('groups-web.modal.out.pending-groups.description')}
          isOpen={isOpened}
          onRequestClose={this.closeWebOut}
        />
      </>
    );
  }
}

const enhance = compose(translate(), withMemberInvites);

export const PendingGroupsMobile = enhance(PendingGroupsMobileComponent);
