import { wixColorParam } from '@wix/tpa-settings/dist/src/getWixColor';
import { wixFontParam } from '@wix/tpa-settings/dist/src/getWixFont';
import { StyleParamType } from '@wix/tpa-settings/dist/src/contexts/StylesContext/StylesContext.types';

import {
  DisplaySettings,
  TextSettings,
  LayoutSettings,
  LayoutStyles,
  LayoutType,
  Alignment,
  ImageRatio,
  DesignStyles,
  DesignSettings,
  ButtonType,
  WidgetSettingsSettings,
} from './types';
import { GroupsRequestSort } from '@wix/social-groups-api/dist/src/model/GroupList/GroupsRequestBuilder';

export const MAX_LAYOUT_SPACING = 100;

export const DEFAULT_GRID_CARD_SPACING = 12;

export const MAX_BUTTON_BORDER_RADIUS = 100;

export const textSettings: TextSettings = {
  groupListTitle: {
    getDefaultValue: () => '',
    key: 'groupListTitle',
  },
  groupMembersLabel: {
    getDefaultValue: () => 'Members',
    key: 'groupMembersLabel',
  },
};

export const displaySettings: DisplaySettings = {
  showHeaderTitle: {
    getDefaultValue: () => true,
    key: 'showHeaderTitle',
  },
  showSearchSorting: {
    getDefaultValue: () => true,
    key: 'showSearchSorting',
  },
  showMemberCount: {
    getDefaultValue: () => true,
    key: 'showMemberCount',
  },
  showAdminBadge: {
    getDefaultValue: () => true,
    key: 'showAdminBadge',
  },
  showImage: {
    getDefaultValue: () => true,
    key: 'showImage',
  },
  mobileShowHeaderTitle: {
    getDefaultValue: () => true,
    key: 'mobileShowHeaderTitle',
  },
  mobileShowSearchSorting: {
    getDefaultValue: () => true,
    key: 'mobileShowSearchSorting',
  },
  mobileShowMemberCount: {
    getDefaultValue: () => true,
    key: 'mobileShowMemberCount',
  },
  mobileShowAdminBadge: {
    getDefaultValue: () => true,
    key: 'mobileShowAdminBadge',
  },
  mobileShowImage: {
    getDefaultValue: () => true,
    key: 'mobileShowImage',
  },
};

export const layoutSettings: LayoutSettings = {
  groupListLayout: {
    getDefaultValue: () => LayoutType.list,
    key: 'groupListLayout',
  },
  mobileGroupListLayout: {
    getDefaultValue: () => LayoutType.list,
    key: 'mobileGroupListLayout',
  },
  headerAlignment: {
    getDefaultValue: () => Alignment.left,
    key: 'headerAlignment',
  },
  imageRatio: {
    getDefaultValue: () => ImageRatio.rectangle,
    key: 'imageRatio',
  },
  mobileImageRatio: {
    getDefaultValue: () => ImageRatio.rectangle,
    key: 'mobileImageRatio',
  },
  gridCardSpacing: {
    getDefaultValue: () => DEFAULT_GRID_CARD_SPACING,
    key: 'gridCardSpacing',
  },
  mobileGridCardSpacing: {
    getDefaultValue: () => DEFAULT_GRID_CARD_SPACING,
    key: 'mobileGridCardSpacing',
  },
  listCardSpacing: {
    getDefaultValue: () => 0,
    key: 'listCardSpacing',
  },
  mobileListCardSpacing: {
    getDefaultValue: () => 0,
    key: 'mobileListCardSpacing',
  },
};

export const layoutStyles: LayoutStyles = {
  [LayoutType.grid]: {
    cardSpacing: {
      name: 'groupCardGridSpacing',
      getDefaultValue: () => DEFAULT_GRID_CARD_SPACING,
      type: StyleParamType.Number,
    },
    mobileCardSpacing: {
      name: 'mobileGroupCardGridSpacing',
      getDefaultValue: () => DEFAULT_GRID_CARD_SPACING,
      type: StyleParamType.Number,
    },
    // TODO: do we really need this, seems like never used
    cardInnerSideSpacing: {
      getDefaultValue: () => 20,
      name: 'groupCardGridInnerSideSpacing',
      type: StyleParamType.Number,
    },
    cardTopBottomSpacing: {
      getDefaultValue: () => 20,
      name: 'groupCardGridTopBottomSpacing',
      type: StyleParamType.Number,
    },
    cardSideSpacing: {
      getDefaultValue: () => 20,
      name: 'groupCardGridSideSpacing',
      type: StyleParamType.Number,
    },
  },
  [LayoutType.list]: {
    cardSpacing: {
      getDefaultValue: () => 12,
      name: 'groupCardListSpacing',
      type: StyleParamType.Number,
    },
    mobileCardSpacing: {
      name: 'mobileGroupCardListSpacing',
      getDefaultValue: () => DEFAULT_GRID_CARD_SPACING,
      type: StyleParamType.Number,
    },
    // TODO: do we really need this, seems like never used
    cardInnerSideSpacing: {
      getDefaultValue: () => 20,
      name: 'groupCardListInnerSideSpacing',
      type: StyleParamType.Number,
    },
    cardTopBottomSpacing: {
      getDefaultValue: () => 20,
      name: 'groupCardListTopBottomSpacing',
      type: StyleParamType.Number,
    },
    cardSideSpacing: {
      getDefaultValue: () => 20,
      name: 'groupCardListSideSpacing',
      type: StyleParamType.Number,
    },
  },
  [LayoutType.listWidget]: {
    cardSpacing: {
      getDefaultValue: () => 0,
      name: 'groupCardListSpacing',
      type: StyleParamType.Number,
    },
    // TODO: do we really need this, seems like never used
    cardInnerSideSpacing: {
      getDefaultValue: () => 0,
      name: 'groupCardListInnerSideSpacing',
      type: StyleParamType.Number,
    },
    cardTopBottomSpacing: {
      getDefaultValue: () => 20,
      name: 'groupCardListTopBottomSpacing',
      type: StyleParamType.Number,
    },
    cardSideSpacing: {
      getDefaultValue: () => 0,
      name: 'groupCardListSideSpacing',
      type: StyleParamType.Number,
    },
  },
};

export const designSettings: DesignSettings = {
  buttonType: {
    getDefaultValue: () => ButtonType.rectangle,
    key: 'buttonType',
  },
};

export const designStyles: DesignStyles = {
  text: {
    listTitle: {
      color: {
        name: 'listTitleColor',
        type: StyleParamType.Color,
        getDefaultValue: wixColorParam('black/white'),
      },
      font: {
        name: 'listTitleFont',
        type: StyleParamType.Font,
        getDefaultValue: wixFontParam('Heading-S'),
      },
      mobileFontSize: {
        name: 'mobileListTitleFontSize',
        type: StyleParamType.Number,
        getDefaultValue: () => 20,
      },
    },
    groupName: {
      color: {
        name: 'groupNameColor',
        type: StyleParamType.Color,
        getDefaultValue: wixColorParam('black/white'),
      },
      font: {
        name: 'groupNameFont',
        type: StyleParamType.Font,
        getDefaultValue: wixFontParam('Body-L'),
      },
      mobileFontSize: {
        name: 'mobileGroupNameFontSize',
        type: StyleParamType.Number,
        getDefaultValue: () => 20,
      },
    },
    colorOnHover: {
      name: 'groupNameColorOnHover',
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-8'),
    },
    searchSortingColor: {
      name: 'searchSortingColor',
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('black/white'),
    },
    groupInfo: {
      color: {
        name: 'groupInfoColor',
        type: StyleParamType.Color,
        getDefaultValue: wixColorParam('primery-2'),
      },
      font: {
        name: 'groupInfoFont',
        type: StyleParamType.Font,
        getDefaultValue: wixFontParam('Body-S'),
      },
      mobileFontSize: {
        name: 'mobileGroupInfoFontSize',
        type: StyleParamType.Number,
        getDefaultValue: () => 14,
      },
    },
  },
  button: {
    color: {
      name: 'buttonColor',
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-8'),
    },
    background: {
      name: 'buttonBackground',
      type: StyleParamType.Color,
      getDefaultValue: wixColorParam('color-8'),
    },
    text: {
      color: {
        name: 'buttonTextColor',
        type: StyleParamType.Color,
        getDefaultValue: wixColorParam('white/black'),
      },
      font: {
        name: 'buttonTextFont',
        type: StyleParamType.Font,
        getDefaultValue: wixFontParam('Body-M'),
      },
      mobileFontSize: {
        name: 'mobileButtonTextFontSize',
        type: StyleParamType.Number,
        getDefaultValue: () => 16,
      },
    },
    borderRadius: {
      getDefaultValue: () => 100,
      name: 'buttonBorderRadius',
      type: StyleParamType.Number,
    },
  },
  backgroundBorder: {
    card: {
      background: {
        color: {
          name: 'cardBackgroundColor',
          type: StyleParamType.Color,
          getDefaultValue: wixColorParam('color-1'),
        },
      },
    },
    app: {
      background: {
        color: {
          name: 'appBackgroundColor',
          type: StyleParamType.Color,
          getDefaultValue: wixColorParam('color-1'),
        },
      },
    },
    border: {
      color: {
        name: 'cardBorderColor',
        type: StyleParamType.Color,
        getDefaultValue: wixColorParam('color-5', 0.2),
      },
      width: {
        name: 'cardBorderWidth',
        type: StyleParamType.Number,
        getDefaultValue: () => 1,
      },
    },
  },
};

export const settingsSettings: WidgetSettingsSettings = {
  sortBy: {
    getDefaultValue: () => GroupsRequestSort.RECENT_ACTIVITY,
    key: 'sortBy',
  },
};
