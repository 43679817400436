import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { isGroupPrivate, isJoined } from '@wix/social-groups-api';

export function getGroupUrl(
  groupSectionUrl: string,
  group: ApiTypes.v1.GroupResponse,
) {
  if (!groupSectionUrl) {
    return;
  }
  let tab = `discussion`;
  if (!isJoined(group) && isGroupPrivate(group)) {
    tab = 'about';
  }
  return `${groupSectionUrl}/${group.slug}/${tab}`;
}
