import * as React from 'react';
import styles from './GroupTitleLink.st.css';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';

interface GroupTitleLinkProps {
  groupUrl: string;
  title: ApiTypes.v1.GroupResponse['details']['title'];
}

export const GroupTitleLink: React.FC<GroupTitleLinkProps> = ({
  groupUrl,
  title,
}) => {
  return (
    <a data-hook="title-link" className={styles.link} href={groupUrl}>
      {title}
    </a>
  );
};
