import * as React from 'react';
import { withExperiments } from '@wix/wix-experiments-react';
import { Groups } from '../Groups';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { DATA_HOOKS } from './dataHooks';

export class AppComponent extends React.Component {
  render() {
    return (
      <div data-hook={DATA_HOOKS.root}>
        <Groups />
      </div>
    );
  }
}

const enhance = compose(withExperiments);

export const App = enhance(AppComponent);
