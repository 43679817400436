import * as React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { GroupList } from '../GroupList';
import CreateGroupModal from '../modals/CreateGroupModal/CreateGroupModal';
import { Spinner } from '@wix/social-groups-common/dist/src/components/Spinner';
import withGroups from '../../contexts/withGroups';
import {
  withAppSettings,
  WithAppSettingsProps,
} from '../../contexts/withAppSettings';
import withGroupsActions, {
  WithGroupsActionsProps,
} from '../../contexts/withGroupsActions';
import {
  MembershipQuestionsModal,
  ProfilePrivacyDialog,
  withAppToasts,
  withTPAConfig,
} from '@wix/social-groups-common/dist/src/components';
import { WithAppToastsProps } from '@wix/social-groups-common/dist/src/types';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import {
  canCreateGroup,
  isProfilePublic,
  willBePendingGroup,
} from '@wix/social-groups-api';
import { DATA_HOOKS } from './dataHooks';
import {
  withCurrentUser,
  WithCurrentUserProps,
} from '../../contexts/withCurrentUser';
import {
  tryToCallBi,
  withBiLogger,
  WithBiLoggerProps,
  withWixSiteMembers,
  WixSiteMember,
  WixSiteMemberActions,
} from '@wix/social-groups-common/dist/src/context';
import { WithGroupsProps } from '../../contexts/withGroupsProps';
import { UpdateProgress } from '@wix/social-groups-common/dist/src/components/ContentEditor/UpdateProgress';
import {
  InjectedExperimentsProps,
  withExperiments,
} from '@wix/wix-experiments-react';
import { GroupWrapper } from '@wix/social-groups-api/dist/src/model/Group/GroupWrapper';

export interface GroupsProps {}

export interface GroupsState {
  isCreateGroupModalOpened: boolean;
  isGroupCreating: boolean;
  isProfileDialogOpen: boolean;
}

export type ComponentProps = InjectedTranslateProps &
  GroupsProps &
  WithCurrentUserProps &
  WithGroupsProps &
  WithGroupsActionsProps &
  WithAppToastsProps &
  WixSiteMember &
  WixSiteMemberActions &
  WithAppSettingsProps & { mobile: boolean } & WithBiLoggerProps &
  InjectedExperimentsProps;

export class GroupsSectionComponent extends React.Component<
  ComponentProps,
  GroupsState
> {
  readonly state: GroupsState = {
    isCreateGroupModalOpened: false,
    isGroupCreating: false,
    isProfileDialogOpen: false,
  };

  componentDidUpdate(
    prevProps: Readonly<ComponentProps>,
    prevState: Readonly<GroupsState>,
  ): void {
    if (!prevProps.promptPublicProfile && this.props.promptPublicProfile) {
      this.openProfileModal();
    }
  }

  componentDidMount() {
    tryToCallBi(async () => {
      await this.props.biLogger.groupsPageView({
        origin: 'list view',
      });
    });
  }

  closeCreateGroupModal = () => {
    this.setState({ isCreateGroupModalOpened: false });
  };

  emptyStateCreateGroupButton = () => {
    tryToCallBi(async () => {
      await this.props.biLogger.groupsCreateGroupClick({
        origin: 'empty_state_btn',
      });
    });

    this.openCreateGroupModal();
  };

  openCreateGroupModal = () => {
    if (this.isProfilePrivate()) {
      return this.openProfileModal();
    }
    if (this.props.currentUser.loggedIn) {
      this.setState({ isCreateGroupModalOpened: true });
    } else {
      this.props.actions.promptLogin();
    }
  };

  handleCreateGroupClick = () => {
    tryToCallBi(async () => {
      await this.props.biLogger.groupsCreateGroupClick({
        origin: 'plus_btn',
      });
    });

    this.openCreateGroupModal();
  };

  render() {
    const {
      actions,
      createGroupPolicy,
      currentUser,
      t,
      mobile,
      updateProgress,
      experiments,
    } = this.props;

    if (updateProgress === UpdateProgress.UPDATING) {
      return <Spinner offset="L" label={t('groups-web.group-list.loading')} />;
    }
    const { isCreateGroupModalOpened } = this.state;

    const shouldShowCreateGroupButton = canCreateGroup(
      currentUser,
      createGroupPolicy,
    );

    const withPendingGroupExplanation =
      experiments.enabled('specs.groups.CreateGroupPendingGroupExplanation') &&
      willBePendingGroup(currentUser, createGroupPolicy);

    return (
      <>
        <GroupList
          data-hook={DATA_HOOKS.groupList}
          onCreateGroupClick={this.emptyStateCreateGroupButton}
          goToGroup={actions ? actions.goToGroup : null}
          withCTA={shouldShowCreateGroupButton}
          mobile={mobile}
        />
        <CreateGroupModal
          withPendingGroupExplanation={withPendingGroupExplanation}
          isOpen={isCreateGroupModalOpened}
          createGroup={actions && actions.createGroup}
          onRequestClose={this.closeCreateGroupModal}
        />
        {this.isProfilePrivate() && (
          <ProfilePrivacyDialog
            onRequestClose={this.closeProfileModal}
            onChangeProfile={this.changeProfile}
            isOpen={this.state.isProfileDialogOpen}
          />
        )}
        {this.renderMembershipQuestionsStep()}
      </>
    );
  }

  private renderMembershipQuestionsStep() {
    const {
      showMembershipQuestionsStepForGroup,
      membershipQuestions,
      actions,
    } = this.props;
    const groupWrapper = new GroupWrapper(showMembershipQuestionsStepForGroup);
    const groupId = showMembershipQuestionsStepForGroup
      ? showMembershipQuestionsStepForGroup.groupId
      : '';
    return (
      <MembershipQuestionsModal
        isOpen={!!showMembershipQuestionsStepForGroup}
        questions={membershipQuestions}
        groupId={
          showMembershipQuestionsStepForGroup
            ? showMembershipQuestionsStepForGroup.groupId
            : ''
        }
        groupName={groupWrapper.getTitle()}
        onSubmit={answers => {
          actions.handleGroupCardCTA(
            showMembershipQuestionsStepForGroup,
            answers,
          );
        }}
        onViewGroupClick={() => actions.goToGroup(groupId)}
        onRequestClose={() => actions.closeMembershipQuestionsStep()}
      />
    );
  }

  private openProfileModal() {
    this.setState({ isProfileDialogOpen: true });
  }

  private readonly changeProfile = () => {
    const { makeProfilePublic, currentSiteMember } = this.props;
    currentSiteMember && makeProfilePublic(currentSiteMember.id);
  };
  private readonly closeProfileModal = () =>
    this.setState({ isProfileDialogOpen: false });

  private isProfilePrivate() {
    return (
      this.props.currentSiteMember &&
      !isProfilePublic(this.props.currentSiteMember)
    );
  }
}

const enhance = compose(
  withGroups,
  withCurrentUser,
  withGroupsActions,
  withAppToasts,
  withWixSiteMembers,
  withAppSettings,
  translate(),
  withTPAConfig,
  withBiLogger,
  withExperiments,
);
export const GroupsSection = enhance(
  GroupsSectionComponent,
) as React.ComponentType<GroupsProps>;
