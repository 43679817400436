import { AppSettingsContextValue } from '../contexts/AppSettingsContextValue';
import { getSettingsKeyFor } from '@wix/social-groups-common/dist/src/utils/utils';

function getDisplaySettings(settings: AppSettingsContextValue) {
  return settings && settings.display;
}

function isUndefinedOrTrue(value: any) {
  return value === undefined || !!value;
}

export const showGroupCTA = (settings: AppSettingsContextValue) => {
  const display = getDisplaySettings(settings);
  return display && isUndefinedOrTrue(display.showButton);
};

export const showGroupLogo = (
  settings: AppSettingsContextValue,
  isMobile: boolean,
) => {
  const display = getDisplaySettings(settings);
  return (
    display &&
    isUndefinedOrTrue(display[getSettingsKeyFor('showImage', isMobile)])
  );
};

export const showGroupType = (settings: AppSettingsContextValue) => {
  const display = getDisplaySettings(settings);
  return display && isUndefinedOrTrue(display.showGroupType);
};

export const showMemberCount = (
  settings: AppSettingsContextValue,
  isMobile: boolean,
) => {
  const display = getDisplaySettings(settings);
  return display && display[getSettingsKeyFor('showMemberCount', isMobile)];
};

export const showAdminBadge = (
  settings: AppSettingsContextValue,
  isMobile: boolean,
) => {
  const display = getDisplaySettings(settings);
  return display && display[getSettingsKeyFor('showAdminBadge', isMobile)];
};

export const showTitle = (
  settings: AppSettingsContextValue,
  isMobile: boolean,
) => {
  const display = getDisplaySettings(settings);
  return display && display[getSettingsKeyFor('showHeaderTitle', isMobile)];
};

export const showSearchSortingBox = (
  settings: AppSettingsContextValue,
  isMobile: boolean,
) => {
  const display = getDisplaySettings(settings);
  return display && display[getSettingsKeyFor('showSearchSorting', isMobile)];
};
