import * as React from 'react';
import { Button as TPAButton, ButtonProps } from 'wix-ui-tpa/Button';

import {
  withAppSettings,
  WithAppSettingsProps,
} from '../../contexts/withAppSettings';

import styles from './Button.st.css';
import { withTPAConfig } from '@wix/social-groups-common';
import { TPAComponentsConfig } from 'wix-ui-tpa/dist/es/src';

const ButtonComponent: React.FC<WithAppSettingsProps &
  ButtonProps &
  TPAComponentsConfig> = ({ appSettings, ...props }) => (
  <TPAButton
    {...props}
    {...styles(
      'root',
      {
        type: appSettings.design.buttonType,
        mobile: props.mobile,
      },
      props,
    )}
  />
);

export const Button = withTPAConfig(withAppSettings(ButtonComponent));
