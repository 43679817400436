import * as React from 'react';
import { AppSettingsContextValue } from './AppSettingsContextValue';

export const AppSettingsContext = React.createContext<AppSettingsContextValue>({
  text: null,
  display: null,
  layout: null,
  design: null,
  settings: null,
});
