import * as React from 'react';
import { Search, withTPAConfig } from '@wix/social-groups-common';
import { SortBy } from './SortBy';
import { DATA_HOOKS } from './dataHooks';
import styles from './SearchSortingBox.st.css';
import { AppRootActions } from '../App/types';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { compose } from '@wix/social-groups-common/dist/src/compose';

export interface SearchSortingBoxProps {
  onSearch: AppRootActions['searchGroups'];
  onSort: AppRootActions['sortGroups'];
  initialSortSelectedId: string;
}

export const SearchSortingBoxComponent: React.FC<SearchSortingBoxProps &
  InjectedTranslateProps & {
    mobile: boolean;
  }> = ({ mobile, t, onSort, initialSortSelectedId, onSearch }) => (
  <div {...styles('root', { mobile })} data-hook={DATA_HOOKS.searchSortingBox}>
    <SortBy
      onChange={onSort}
      initialSelectedId={initialSortSelectedId}
      {...styles('sortBy')}
    />
    <Search
      withCloseButton={true}
      placeholder={t('groups-web.search.placeholder')}
      onChange={onSearch}
      {...styles('search', {})}
    />
  </div>
);

const enhance = compose(translate(), withTPAConfig);

export const SearchSortingBox = enhance(
  SearchSortingBoxComponent,
) as React.ComponentType<SearchSortingBoxProps>;
