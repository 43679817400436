import {
  IStyleParam,
  StyleParamType,
} from '@wix/tpa-settings/dist/src/contexts/StylesContext';

import {
  ISettingsParam,
  ISettingsParams,
} from '@wix/tpa-settings/dist/src/contexts/SettingsContext';

import { GroupsRequestSort } from '@wix/social-groups-api';

export enum Alignment {
  left = 'left',
  right = 'right',
  center = 'center',
}

export enum LayoutType {
  grid = 'grid',
  list = 'list',
  listWidget = 'listWidget',
}

export enum ButtonType {
  rectangle = 'rectangle',
  rounded = 'rounded',
}

export enum ImageRatio {
  rectangle = 'rectangle',
  square = 'square',
}

export interface DesignStyles {
  text: {
    listTitle: {
      font: IStyleParam<StyleParamType.Font>;
      color: IStyleParam<StyleParamType.Color>;
      mobileFontSize: IStyleParam<StyleParamType.Number>;
    };
    groupName: {
      font: IStyleParam<StyleParamType.Font>;
      color: IStyleParam<StyleParamType.Color>;
      mobileFontSize: IStyleParam<StyleParamType.Number>;
    };
    colorOnHover: IStyleParam<StyleParamType.Color>;
    searchSortingColor: IStyleParam<StyleParamType.Color>;
    groupInfo: {
      font: IStyleParam<StyleParamType.Font>;
      color: IStyleParam<StyleParamType.Color>;
      mobileFontSize: IStyleParam<StyleParamType.Number>;
    };
  };
  button: {
    borderRadius: IStyleParam<StyleParamType.Number>;
    color: IStyleParam<StyleParamType.Color>;
    background: IStyleParam<StyleParamType.Color>;
    text: {
      font: IStyleParam<StyleParamType.Font>;
      color: IStyleParam<StyleParamType.Color>;
      mobileFontSize: IStyleParam<StyleParamType.Number>;
    };
  };
  backgroundBorder: {
    card: {
      background: {
        color: IStyleParam<StyleParamType.Color>;
      };
    };
    app: {
      background: {
        color: IStyleParam<StyleParamType.Color>;
      };
    };
    border: {
      color: IStyleParam<StyleParamType.Color>;
      width: IStyleParam<StyleParamType.Number>;
    };
  };
}

interface LayoutStyle {
  cardSpacing: IStyleParam<StyleParamType.Number>;
  mobileCardSpacing?: IStyleParam<StyleParamType.Number>;
  cardSideSpacing: IStyleParam<StyleParamType.Number>;
  cardTopBottomSpacing: IStyleParam<StyleParamType.Number>;
  // TODO: do we really need this, seems like never used
  cardInnerSideSpacing: IStyleParam<StyleParamType.Number>;
}

export interface LayoutStyles {
  [LayoutType.grid]: LayoutStyle;
  [LayoutType.list]: LayoutStyle;
  [LayoutType.listWidget]: LayoutStyle;
}

export type DesignSettings = ISettingsParams<{
  buttonType: ISettingsParam<ButtonType>;
}>;

export interface ILayoutSettings {
  imageRatio: ISettingsParam<ImageRatio>;
  groupListLayout: ISettingsParam<LayoutType>;
  gridCardSpacing: ISettingsParam<number>;
}

export type LayoutSettings = ISettingsParams<
  ILayoutSettings & {
    headerAlignment: ISettingsParam<Alignment>;
    listCardSpacing: ISettingsParam<number>;
    mobileImageRatio: ISettingsParam<ImageRatio>;
    mobileGroupListLayout: ISettingsParam<LayoutType>;
    mobileListCardSpacing: ISettingsParam<number>;
    mobileGridCardSpacing: ISettingsParam<number>;
  }
>;

export type DisplaySettings = ISettingsParams<{
  showHeaderTitle: ISettingsParam<boolean>;
  showSearchSorting: ISettingsParam<boolean>;
  showMemberCount: ISettingsParam<boolean>;
  showAdminBadge: ISettingsParam<boolean>;
  showImage: ISettingsParam<boolean>;
  mobileShowHeaderTitle: ISettingsParam<boolean>;
  mobileShowSearchSorting: ISettingsParam<boolean>;
  mobileShowMemberCount: ISettingsParam<boolean>;
  mobileShowAdminBadge: ISettingsParam<boolean>;
  mobileShowImage: ISettingsParam<boolean>;
}>;

export type TextSettings = ISettingsParams<{
  groupListTitle: ISettingsParam<string>;
  groupMembersLabel: ISettingsParam<string>;
}>;

export type WidgetLayoutSettings = ISettingsParams<
  ILayoutSettings & {
    numberGroups: ISettingsParam<number>;
  }
>;

export type WidgetSettingsSettings = ISettingsParams<{
  sortBy: ISettingsParam<GroupsRequestSort>;
}>;

export type WidgetDisplaySettings = ISettingsParams<{
  showGroupType: ISettingsParam<boolean>;
  showActivity: ISettingsParam<boolean>;
  showButton: ISettingsParam<boolean>;
}>;
