import * as React from 'react';

import { App } from './App';
import { GroupPageWithStyles } from './types';
import { AppToastsProvider } from '@wix/social-groups-common';

import { MemberInvitesProvider } from '@wix/social-groups-common/dist/src/context';
import { GroupsAppProvider } from '../../contexts/GroupsAppProvider';

class ApplicationRoot extends React.Component<GroupPageWithStyles> {
  static displayName = 'ApplicationRoot';
  // TODO: we cannot guarantee the order of execution controllers and component's renders.
  // So for preventing an error if we do not have some data from config componentDidCatch was added
  componentDidCatch(error, info) {
    console.log(error, info);
  }

  render() {
    const {
      toasts,
      appSettings,
      memberInvitesLink,
      loadMemberInvitesLink,
    } = this.props;

    if (!appSettings) {
      return null;
    }
    return (
      <GroupsAppProvider {...this.props}>
        <AppToastsProvider value={toasts}>
          <MemberInvitesProvider
            value={{ memberInvitesLink, loadMemberInvitesLink }}
          >
            <App />
          </MemberInvitesProvider>
        </AppToastsProvider>
      </GroupsAppProvider>
    );
  }
}

export default ApplicationRoot;
