import * as React from 'react';

import { AppSettingsContext } from './AppSettingsContext';
import { AppSettingsContextValue } from './AppSettingsContextValue';

export interface WithAppSettingsProps {
  appSettings: AppSettingsContextValue;
}

export const withAppSettings = <P extends WithAppSettingsProps>(
  WrappedComponent: React.ComponentType<P>,
): React.FC<Omit<P, keyof WithAppSettingsProps>> => props => (
  <AppSettingsContext.Consumer>
    {appSettings =>
      appSettings && (
        <WrappedComponent {...({ appSettings, ...props } as any)} />
      )
    }
  </AppSettingsContext.Consumer>
);
